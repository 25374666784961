import React, { Component } from 'react';
import Workspace from './Workspace'


import PageHeader from './PageHeader/PageHeader'
import Sidebar from './Sidebar/Sidebar'
import Error from './Error/Error'
import Eula from './Eula/Eula'

import { invokeApi, logOut } from '../../../libs/adal'
import Viz from '../../../libs/tableau'

import './Workspace.css';


export default class WorkspaceContainer extends Component {
    constructor(props) {
        super(props);
        this.sessionTimeoutHandle = null;
        this.state = {
            viz: undefined,
            sites: null,
            activeDashboard: '',
            isLoadingResources: true,
            isLoadingEulaConsent: false,
            isCustomView: false,
            activeWorkbookId: '',
            error: false,
            errorType: 'error',
            userSettings: {hasUserConsentedToEula: true},
            hideToolbar: props.hideToolbar,
            isMenuOpen: false,
            handleToggleMenu : this.handleToggleMenu,
            collapse: null,
        };
        this.tableauEvents = [
            { "type": window.tableau.TableauEventName.PARAMETER_VALUE_CHANGE, "handler": this.refreshSessionTimeout.bind(this)},
            { "type": window.tableau.TableauEventName.FILTER_CHANGE, "handler": this.refreshSessionTimeout.bind(this)},
            { "type": window.tableau.TableauEventName.CUSTOM_VIEW_LOAD, "handler": this.refreshSessionTimeout.bind(this)},
            { "type": window.tableau.TableauEventName.TAB_SWITCH, "handler": this.refreshSessionTimeout.bind(this)},
            { "type": window.tableau.TableauEventName.MARKS_SELECTION, "handler": this.refreshSessionTimeout.bind(this)},
            { "type": window.tableau.TableauEventName.URL_ACTION, "handler": this.refreshSessionTimeout.bind(this)},
            { "type": window.tableau.TableauEventName.STORY_POINT_SWITCH, "handler": this.refreshSessionTimeout.bind(this)},

            // needed for updating the sidebar menu, if the user clicks a tab inside the viz element
            { "type": window.tableau.TableauEventName.CUSTOM_VIEW_LOAD, "handler": this.highlightCurrentWorkbookInMenu.bind(this)}
        ];
    }

    highlightCurrentWorkbookInMenu = (event) => {
        let name = this.state.viz.getViz().getWorkbook().getActiveSheet().getName()
        this.setState({
            activeDashboard: name
        })
    }
     handleToggleMenu = (isMenuOpen) => {
        this.setState( {isMenuOpen: isMenuOpen});
         this.refreshSessionTimeout();
    }

    refreshSessionTimeout = () => {
        if (this.state.error) return;

        //console.log("Refreshing timer");

        //var timeoutLimit = 0.75 * 60000;
        var timeoutLimit = 59 * 60000;
     
        if (this.sessionTimeoutHandle != null)
        {
            clearTimeout(this.sessionTimeoutHandle);
            this.sessionTimeoutHandle = null;
        }

        var that = this;

        this.sessionTimeoutHandle = setTimeout(() => {
            console.log("Timeout occured");
            that.setState({ 
                error: true,
                errorType: "sessionError"
            })
        }, timeoutLimit);

        return true;
    }

    componentWillUnmount() {
        if (this.sessionTimeoutHandle != null)
        {
            clearTimeout(this.sessionTimeoutHandle);
            this.sessionTimeoutHandle = null;
        }
    }

    parseHrefGoToPath = () => {
        let href = window.location.href;

        if ( href.includes( "/views/" ) ) {
            let breadcrumbs = href.toString().split( "/" );
            let viewsPosition;
            for ( let i = 0; i < breadcrumbs.length; i++ ) {
                if(breadcrumbs[i].match("views") !=null){
                    viewsPosition = i;
                }
            }
            let path = breadcrumbs[viewsPosition] + "/" + breadcrumbs[viewsPosition+1] + "/" + breadcrumbs[viewsPosition+2];
            let contentUrl = breadcrumbs[viewsPosition-1];
            let currentSite = this.state.sites.filter(function(item) {
                return item.name === contentUrl;
            });

            var workbooks = currentSite[0].workbooks;
            var id;
            var name;
            loop1:
            for( var i = 0; i < workbooks.length; i++ ) {
                var currentWorkbook = workbooks[i];
                for( var j = 0; j < currentWorkbook.dashboards.length; j++ ) {
                    var currentDashboard = currentWorkbook.dashboards[j];
                    if( currentDashboard.path === path ) {
                        id = currentWorkbook.id;
                        name = currentWorkbook.name;
                        break loop1;
                    }
                }
            }
            if (id  === undefined || name === undefined){
                this.setState({
                    error: true,
                    isLoadingResources: false,
                    errorType: "workBookNotFoundError"
                })
            } else {
                this.toggle(contentUrl);
                this.goToNewPath({path,name}, id, contentUrl, false);
            }
        }
    }

    componentDidMount() {
        this.setState({
            isMenuOpen: false
        })
        this.props.ai.trackPageView("work");
        invokeApi({ path: "/dashboards" })
            .then((response) => {
                if (response.ok) {
                    return response.json()
                } else {
                    return Promise.reject("Couldn't get list of dashboards");
                }
            })
            .then((sites) => {
                if (sites[0] && sites[0].workbooks[0] && sites[0].workbooks[0].dashboards[0]) {
                    this.setState({
                        sites: sites,
                    },
                    //this.parseHrefGoToPath
                    );
                    this.setState({
                        collapse: sites[0].name,
                    });
                    return this.getTicket(sites[0].contentUrl)
                } else {
                    return Promise.reject("Dashboards list is empty");
                }
            })
            .then((ticket) => {
                this.setState({
                    viz: new Viz(true)
                }, () => {
                    this.parseHrefGoToPath();
                    const callback = () => { this.setState({ isLoadingResources: false, }) }
                    this.state.viz.createViz(this.vizContainer, this.state.sites[0].workbooks[0].dashboards[0].path, this.state.sites[0].contentUrl, ticket, callback, this.tableauEvents, this.props.toolbarHidden).then((viz) => {
                        this.setState({
                            activeWorkbookId: this.state.sites[0].workbooks[0].id,
                            activeDashboard: this.state.sites[0].workbooks[0].dashboards[0].name
                        })
                    })
                })

            })
            .catch((error) => {
                this.props.ai.trackException(error, null, null, 4)
                this.setState({
                    error: true,
                    isLoadingResources: false
                })
            })

            invokeApi({ path: "/user-settings" })
            .then((response) => {
                if (response.ok) {
                    return response.json()
                } else {
                    return Promise.reject("Couldn't get user settings");
                }
            })
            .then((userSettings) => {
                this.setState({userSettings: userSettings} );
            })
            .catch((error) => {
                this.props.ai.trackException(error, null, null, 4)
                this.setState({
                    isLoadingResources: false
                })
            })
    }

    getTicket = async (contentUrl) => {
        return invokeApi({ path: `/trusted-ticket?contentUrl=${contentUrl}` })
            .then((response) => {
                if (response.ok) {
                    return response.text()
                } else {
                    return Promise.reject("Coudln't get a ticket");
                }
            })
    }

    goToNewPath = ({ path, name }, workbookId, contentUrl, isTriggeredFromSidebar) => {
        this.refreshSessionTimeout();
        if (this.state.activeWorkbookId === workbookId) {
            if (!this.state.isLoadingResources) {
                this.setState({ isLoadingResources: true, activeDashboard: name });
                this.state.viz.getViz().getWorkbook().activateSheetAsync(name)
                    .then(() => {
                        this.setState({ isLoadingResources: false });
                    })
                    .otherwise((error) => {
                        this.props.ai.trackException(error, null, null, 4)
                        this.setState({
                            error: true,
                            isLoadingResources: false,
                            errorType: "pathError"
                        })
                    })
            }
        } else {
            this.getTicket(contentUrl)
                .then((ticket) => {
                    this.setState({ isLoadingResources: true })
                    const callback = () => {
                        // gets called after onFirstInteractive() when the Viz Object has  been initialized
                        let href = window.location.href;
                        if ( !isTriggeredFromSidebar && href.includes( "/views/" ) ) {

                            let breadcrumbs = href.toString().split( "/" );
                            let viewsPosition;
                            for ( let i = 0; i < breadcrumbs.length; i++ ) {
                                if(breadcrumbs[i].match("views") !=null){
                                    viewsPosition = i;
                                }
                            }
                            let userGuid = breadcrumbs[viewsPosition+3];

                            if ( userGuid !== undefined ) {
                                this.setState({
                                    isCustomView: true
                                })
                                let path = breadcrumbs[viewsPosition] + "/" + breadcrumbs[viewsPosition+1] + "/" + breadcrumbs[viewsPosition+2];
                                this.state.viz.getViz().getWorkbook().getCustomViewsAsync()
                                    .then( ( customViews ) => {
                                        if (customViews.length) {
                                            // custom views found
                                            for ( var i = 0; i < customViews.length; i++ ) {
                                                if ( customViews[i].getUrl().indexOf( path ) !== -1 ) {
                                                    this.state.viz.getViz().getWorkbook().showCustomViewAsync( customViews[i].getName() ).then( () => {
                                                        this.setState( {isLoadingResources: false} );
                                                    } ).otherwise( ( error ) => {
                                                        this.setState( {
                                                            error: true,
                                                            isLoadingResources: false,
                                                            errorType: "pathError"
                                                        } )
                                                    } );
                                                }
                                            }
                                        } else {
                                            // no custom views found, remove the loading indicator
                                            this.setState( {
                                                isLoadingResources: false,
                                            });
                                        }
                                    })
                                    .otherwise((error) => {
                                        this.setState({
                                            error: true,
                                            isLoadingResources: false,
                                            errorType: "pathError"
                                        })
                                    });
                            } else {
                            this.setState({ isLoadingResources: false });
                        }
                        } else {
                            this.setState({ isLoadingResources: false });
                        }
                    }

                    this.state.viz.createViz( this.vizContainer, path, contentUrl, ticket, callback, this.tableauEvents, this.props.toolbarHidden ).then( () => {
                        this.setState( {
                            activeWorkbookId: workbookId,
                            activeDashboard: name,
                        });
                        // Scroll to active workbook. The timeout is needed because of the collapse animation. If we would trigger the Scroll immediately, the menu wouldn't be open yet.
                        setTimeout(function() {
                            document.getElementById(workbookId).scrollIntoView({ behavior: "smooth", inline: "nearest" });
                        }, 400);
                    })

                }).catch((error) => {
                this.props.ai.trackException(error, null, null, 4)
                this.setState({
                    error: true,
                    isLoadingResources: false,
                    errorType: "pathError"
                })
            })
        }
        // In case of mobile view, also close the menu after a click.
        if (window.innerWidth < 1024){
            this.setState( {isMenuOpen: false});
        }
    }

    injectTimerRefresh(callback)
    {
        this.refreshSessionTimeout.apply(this);
        callback();
    }

    hasViz = () => this.state.viz && this.state.viz.getViz();
    exportToPDF = () => this.hasViz() && this.refreshSessionTimeout() && this.state.viz.exportToPDF();
    downloadFile = () => this.hasViz() && this.refreshSessionTimeout() && this.state.viz.exportToJSON();
    downloadImage = () => this.hasViz() && this.refreshSessionTimeout() && this.state.viz.exportToImage();
    exportAsCrosstab = () => this.hasViz() && this.refreshSessionTimeout() && this.state.viz.exportAsCrosstab();
    revertAllAsync = () => {
        if (this.hasViz()) {
            this.setState({ isLoadingResources: true })
            this.state.viz.revertAllAsync().then(() => { this.setState({ isLoadingResources: false }) })
            this.refreshSessionTimeout();
        }
    }
    refreshDataAsync = () => {
        if (this.hasViz()) {
            this.setState({ isLoadingResources: true })
            this.state.viz.refreshDataAsync().then(() => { this.setState({ isLoadingResources: false }) })
            this.refreshSessionTimeout();
        }
    }
  
    redirectToFleetbase = () => window.open(`https://www.fleetbase.de/index.php`)
    redirectToIrisnet = () => window.open(`https://iris-net.dataforce.de`)

    openFullScreen = () => {
        if (!this.hasViz()) return;
        const el = this.vizContainer.parentNode;
        if (el.requestFullscreen) el.requestFullscreen();
        else if (el.mozRequestFullScreen) el.mozRequestFullScreen();
        else if (el.webkitRequestFullscreen) el.webkitRequestFullscreen();
        else if (el.msRequestFullscreen) el.msRequestFullscreen();
        this.refreshSessionTimeout();
    }

    consentToEula = () => {
        this.setState({
            isLoadingEulaConsent: true
        })
        invokeApi({method: "POST", path: "/user-settings/consent" })
            .then((response) => {
                if (response.ok) {
                    this.setState({ userSettings: {...this.state.userSettings, hasUserConsentedToEula: true} });
                } else {
                    return Promise.reject("Couldn't register consent to EULA.");
                }
            })
            .catch((error) => {
                this.props.ai.trackException(error, null, null, 4)
                this.setState({
                    isLoadingEulaConsent: false
                })
            })

    }

    toggle = (siteName) => {
        if (!this.state.collapse || siteName !== this.state.collapse) {
            this.setState({
                collapse: siteName
            });
        } else if (this.state.collapse === siteName) {
            this.setState({ collapse: false })
        }
        this.refreshSessionTimeout();
    }

    openSite = (siteName) => {
        if (!this.state.collapse || siteName !== this.state.collapse) {
            this.setState({
                collapse: siteName
            });
        }
        this.refreshSessionTimeout();
    }

    render() {
        const pageHeaderProps = {
            handleLogout: logOut,
            revertAllAsync: this.revertAllAsync,
            openFullScreen: this.openFullScreen,
            refreshDataAsync: this.refreshDataAsync,
            toggleToolbar: this.props.toggleToolbarVisibility,
            isToolbarHidden: this.props.toolbarHidden,
            activeLanguage: this.props.activeLanguage,
            onClickLangPicker: this.props.onClickLangPicker,
            onChangeLanguage: this.props.onChangeLanguage,
            isOpenLangPicker: this.props.isOpenLangPicker,
            redirectToFleetbase: this.redirectToFleetbase,
            redirectToIrisnet: this.redirectToIrisnet,
            isLoadingResources: this.state.isLoadingResources,
            user: this.props.user,
            isMenuOpen: this.state.isMenuOpen,
            handleMenuToggle: this.handleToggleMenu,
        }
        const sidebarProps = {
            activeLanguage: this.props.activeLanguage,
            sites: this.state.sites,
            goToNewPath: this.goToNewPath,
            activeWorkbookId: this.state.activeWorkbookId,
            activeDashboard: this.state.activeDashboard,
            downloadImage: this.downloadImage,
            downloadFile: this.downloadFile,
            exportToPDF: this.exportToPDF,
            exportAsCrosstab : this.exportAsCrosstab,
            toolbarHidden: this.props.toolbarHidden,
            isMenuOpen: this.state.isMenuOpen,
            toggle: this.toggle,
            openSite: this.openSite,
            collapse: this.state.collapse,
        }

        const errorProps = {
            activeLanguage: this.props.activeLanguage,
            errorType: this.state.errorType
        }

        const eulaProps = {
            activeLanguage: this.props.activeLanguage,
            consentToEula: this.consentToEula,
            hasUserConsentedToEula: this.state.userSettings.hasUserConsentedToEula,
            isLoadingEulaConsent: this.state.isLoadingEulaConsent,
            pdfPath: "eula.pdf"
        }

        const workspaceProps = {
            toolbarHidden: this.props.toolbarHidden,
            isMenuOpen: this.state.isMenuOpen,
            isLoadingResources: this.state.isLoadingResources,
            isCustomView: this.state.isCustomView,
        }

        return (
            <React.Fragment>
                <Eula {...eulaProps} />
                <Workspace {...workspaceProps }
                    pageHeader={<PageHeader {...pageHeaderProps} />}
                    content={this.state.error ? <Error {...errorProps} /> : <div className="workspace__content-inside" ref={el => this.vizContainer = el}></div>}
                    sidebar={<Sidebar {...sidebarProps} />}
                    timeoutHandler={this.refreshSessionTimeout.bind(this)}
                />
            </React.Fragment>
        )
    }
}